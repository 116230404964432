.register-box {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 0 0 16px;
    border: 1px solid #a5a3a3;
    border-radius: 2px;
    transition: all .2s;
}

.ant-form-item {
    margin-bottom: 10px !important;
}